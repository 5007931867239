#portfolio .container {
    height: fit-content;
}

.projects,
.projectImages {
    margin: 0 auto;
    margin-top: 45px;
    max-width: 1280px!important;
    width: 100%;
    height: auto;
}


.projectImages {
    margin-top: 0;
}

.projects *,
.projectImages * {
    width: 100%;
    margin: 0 auto;
}

@media screen and (max-width: 540px) {
    #portfolio .container .content {
        padding-inline: 20px;
        display: block;
    }

    .projectImages,
    .projects {
        padding-inline: 20px;
    }
}