#services .container .content {
    gap: 20px;
}

#services .container .content img {
    grid-column: 1 / 2;
    max-width: 560px;
    width: 100%;
    object-fit: cover;
}

.serviceContent {
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.serviceContent .heading {
    max-width: 600px;
    width: 100%;
    line-height: 36px;
    color: var(--darkGreen);
}

.serviceList {
    list-style: none;
    display: flex;
    flex-direction: column;
}

.serviceItem {
    padding-block: 9px;
    padding-left: 6px;
    border-bottom: 1px solid var(--darkGreen);
    font-family: var(--Poppins);
    font-weight: 300;
    font-size: 16px;
    cursor: pointer;
    transition: all .3s ease;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.serviceItem:hover {
    padding-left: 10px;
}

.serviceItem:nth-child(1){
    border-top: 1px solid var(--darkGreen);
}

@media screen and (max-width: 1024px){
    #services .container .content {
        padding-inline: 20px;
    }

    #services .container .content .heading {
        font-size: 26px;
    }

    .serviceItem {
        padding-block: 7px;
    }
}

@media screen and (max-width: 540px) {
    .serviceImage {
        display: none;
    }

    #services .container .content {
        padding-inline: 20px;
        display: flex;
        flex-direction: column;
    }

    #services .container .content .heading {
        font-size: 30px;
        line-height: 36px;
    }

    .serviceContent {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
}