.rec-slider-container {
    margin: 0!important;
}
.carouselImage {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: relative;
    filter: brightness(60%);
}

.carouselImage::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(360deg, #000000 -5.75%, rgba(0, 0, 0, 0) 51.54%), linear-gradient(180deg, #000000 -20.11%, rgba(0, 0, 0, 0) 22.43%)!important;
    z-index: 2;
}

.indicator {
    z-index: 5;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 0 0 40px 40px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.indicator button {
    font-family: var(--Poppins);
    font-weight: 200;
    font-size: 50px;
    color: var(--white);
    background-color: transparent;
    outline: none;
    border: none;
    opacity: 60%;
}

@media screen and (max-width: 1024px) {
    .indicator button {
        font-size: 40px;
    }
}

@media screen and (max-width: 540px) {
    .indicator {
        padding: 0 0 10px 0;
        width: 100%;
        flex-direction: row;
        justify-content: center;
    }

    .indicator button {
        font-size: 36px;
    }
}