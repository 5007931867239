.slider {
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (max-width: 1024px){
    #home .container .content .heading {
        max-width: 300px;
        width: 100%;
    }
}

@media screen and (max-width: 540px) {
    #home .container .content {
        padding-top: 50px;
        padding-inline: 20px;
        display: block;
    } 
}