#contact .container .content {
    grid-template-columns: 1fr 1fr;
    row-gap: 40px;
}

#contact .container .content .heading {
    grid-column: 2/3;
    width: 100%;
    line-height: 36px;
    color: var(--darkGreen);
}

.address {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.contactHeading,
.contactHeading a {
    text-decoration: none;
    font-family: var(--Poppins);
    font-weight: 400;
    font-size: 40px;
    color: var(--darkGreen);
    line-height: 56px;
}

.address h3 {
    font-family: var(--Poppins);
    font-weight: 700;
    font-size: 24px;
    color: var(--darkGreen);
}

.address p {
    font-family: var(--Poppins);
    font-weight: 400;
    font-size: 24px;
    color: var(--darkGreen);
}

.address > p > a {
    text-decoration: none;
    cursor: pointer;
    color: var(--darkGreen);
}

#contact .container .content .contact {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}

.contactHeading > a:hover {
    color: var(--darkGreen);
    text-decoration: none;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
}

.form .heading {
    width: 100%;
    color: var(--darkGreen);
}

.contactForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contactForm input,
.contactForm textarea {
    padding: 15px 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
    font-family: var(--Poppins);
    font-weight: 300;
    font-size: 16px;
    color: var(--darkGreen);
    background: transparent;
    resize: none;
    transition: all .3s ease-in;
}

.contactForm input::placeholder,
.contactForm textarea::placeholder {
    color: var(--darkGreen);
}

.contactForm input:focus,
.contactForm textarea:focus {
    border-color: var(--darkGreen);
}

.contactForm select {
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
    background-color: transparent;
    font-family: var(--Poppins);
    font-weight: 300;
    font-size: 16px;
    color: var(--darkGreen);
}

.contactForm select:focus {
    border-color: var(--darkGreen);
}

.contactForm button {
    height: 60px;
    border: none;
    outline: none;
    background: var(--cream);
    font-family: var(--Poppins);
    font-weight: 400;
    font-size: 16px;
    color: var(--darkGreen);
    cursor: pointer;
}

@media screen and (max-width: 1024px){
    #contact .container .content {
        padding-inline: 20px;
    }

    #contact .container .content:first-child {
        margin-bottom: 40px;
    }

    .contactHeading,
    .contactHeading a {
        font-size: 30px;
    }
}

@media screen and (max-width: 540px){
    #contact > .container > .content {
        display: flex!important;
        flex-direction: column-reverse;
        gap: 30px;
    }

    .form {
        flex-direction: column-reverse;
        gap: 30px;
    }

    .contactHeading,
    .contactHeading a {
        font-size: 20px;
        line-height: 24px;
    }

    .address h3 {
        margin-top: 40px;
        line-height: 28px;
    }

    .address p {
        font-size: 20px;
    }

    .address{
        gap: 10px;
    }

    #contact .content:nth-child(2) {
        padding-bottom: 20px;
    }
}