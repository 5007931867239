@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --Poppins: 'Poppins', sans-serif;
    --Playfair: 'Playfair Display', serif;
    --white: #FFFFFF;
    --cream: #EAD1A3;
    --green: #184D47B2;
    --darkGreen: #184D47;
}

.container {
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;
    height: 100%;
}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.content .heading {
    grid-column: 2/3;
    max-width: 400px;
    width: 100%;
}

.content .description {
    grid-column: 2/3;

}

.slider {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.carouselImage {
    position: relative;
    background: linear-gradient(360deg, #000000 -5.75%, rgba(0, 0, 0, 0) 51.54%), linear-gradient(180deg, #000000 -20.11%);
    z-index: -1;
}

.carouselImage::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(360deg, #000000 -5.75%, rgba(0, 0, 0, 0) 51.54%), linear-gradient(180deg, #000000 -20.11%)!important;
    z-index: 2!important;
}

#home .heading {
    z-index: 1;
}

.heading {
    font-family: var(--Poppins);
    font-weight: 200;
    font-size: 40px;
    line-height: 48px;
    color: var(--white);
}

.heading span {
    color: var(--cream);
}

section {
    width: 100vw;
    height: 80vh;
}

/* Portfolio */
#portfolio .container .content .portfolioHeading {
    width: 100%;
    height: fit-content;
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.portfolioHeading .heading {
    color: var(--darkGreen);
}

.projects img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.projectTag:hover {
    background-color: #184D47!important;
    color: var(--white)!important;
}

/* Project Detail */
#detail {
    height: fit-content;
}

#detail .heading {
    color: var(--darkGreen);
}

@media screen and (max-width: 1024px) {
    .heading {
        font-size: 30px;
    }
}