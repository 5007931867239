nav {
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;
}

.logo {
    z-index: 2;
}

.navWrapper {
    height: 80px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
}

.burger {
    display: flex;
    gap: 20;
    flex-direction: column;
    border: none;
    outline: none;
    background: transparent;
    user-select: none;
    appearance: none;
    width: 40px;
    height: 40px;
    position: relative;
    z-index: 10;
}

.burger span {
    margin-bottom: 10px;
    width: 100%;
    height: 2px;
    border-radius: 5px;
    background: var(--darkGreen);
    transition: all .3s ease;
}

.burger.active span {
    background: var(--darkGreen)!important;
}

.burger.active span:nth-child(1){
    transform: translate(0, 12px) rotate(-45deg);
}

.burger.active span:nth-child(2) {
    opacity: 0;
    visibility: hidden;
}

.burger.active span:nth-child(3) {
    transform: translate(0, -12px) rotate(45deg);
}

.navList {
    position: fixed;
    top: 0;
    right: 0%;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20;
    background-color: var(--cream);
    width: 55%;
    height: 100%;
    padding: 150px 0px 100px 75px;
    transition: all .5s ease;
    z-index: 2;
}

.navLink {
    text-decoration: none;
    font-family: var(--Playfair);
    font-weight: 400;
    font-size: 64px;
    color: var(--green);
    text-transform: capitalize;
    transition: all .3s ease-in;
}

.navLink:hover,
.navLink:focus {
    text-decoration: none;
    color: var(--darkGreen);
}

.navLink.active {
    color: var(--darkGreen);
}

@media screen and (max-width: 1280px) {
    .navWrapper {
        padding-inline: 20px;
    }

    .navList {
        padding-top: 100px;
        padding-left: 50px;
    }
}


@media screen and (max-width: 540px) {
    .navWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .navList {
        padding: 150px 20px;
        align-items: center;
        width: 100%;
        gap: 15px;
        z-index: 9;
    }

    .navLink {
        font-size: 50px;
    }

    .burger {
        width: 30px;
        height: 30px;
    }

    .burger span {
        margin-bottom: 6px;
    }

    .burger.active span:nth-child(1){
        transform: translate(0, 8px) rotate(-45deg);
    }

    .burger.active span:nth-child(3) {
        transform: translate(0, -8px) rotate(45deg);
    }
}

@media screen and (max-width: 375px){
    .navList {
        gap: 25px;
    }
    .navLink {
        font-size: 38px;
    }
}